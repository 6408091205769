import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { DefaultLayout } from '../layouts/DefaultLayout';
import { Hero } from '../components/Hero';
import { GatsbyImage } from 'gatsby-plugin-image';
import { TemplateWrapper } from './template-wrapper';
import { Seo } from '../components/Seo';

// ask smiley
const StandardTemplate: React.FC = ({ data: { markdown } }) => {
    return (
        <Fragment>
            <Seo
                title={markdown.frontmatter.meta.title}
                description={markdown.frontmatter.meta.description}
            />
            <Hero {...markdown.frontmatter}>
                <GatsbyImage
                    className="hero-image"
                    alt={markdown.frontmatter.thumbnail.name}
                    image={markdown.frontmatter.thumbnail.childImageSharp.gatsbyImageData}
                />
            </Hero>
            <TemplateWrapper>
                <MDXRenderer images={markdown.frontmatter.images}>{markdown.body}</MDXRenderer>
            </TemplateWrapper>
        </Fragment>
    );
};

export const query = graphql`
    query ($id: String!) {
        markdown: mdx(id: { eq: $id }) {
            frontmatter {
                meta {
                    title
                    description
                }
                title
                slug
                type
                list
                category
                smallImage
                thumbnail {
                    id
                    childImageSharp {
                        gatsbyImageData(quality: 100, placeholder: NONE)
                    }
                    name
                }
                images {
                    id
                    childImageSharp {
                        gatsbyImageData(quality: 100, placeholder: NONE)
                    }
                    name
                }
            }
            body
        }
    }
`;
StandardTemplate.Layout = DefaultLayout;

export default StandardTemplate;
